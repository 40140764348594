
.mainheader{
  position: fixed;
  top:0px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  background-color: rgb(14, 14, 14);
  height: 70px;
  align-items: center;
  z-index: 100;
}

.header-nav-list{
  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  text-decoration: none;
  list-style-type: none;
}

.image-logo-div{
  margin-left:50px ;
  padding-bottom: 15px;
}

a {
  color: white;
}

.logo-image{
  /* position: absolute; */
  height: 40px;
  padding-top: 15px;
}

.header-opts{
  text-decoration: none;
}

.header-signin-out{
  text-decoration:none;
  color:white; 
  background-color:transparent;
  border:none;
  font-size: 1rem;
}

.header-signin-out:active{
  background-color: aqua;
  border-radius: 5px;
  padding: 3px;
}

.user-form-div{
  margin-top: 180px;
  display: flex;
  align-items: center;
  /* border-radius:10px;
  border: 1px solid black */
}

.user-forms{
  display: grid;
  grid-template-rows: 80px 80px 80px 80px ;
  align-items: center;
  justify-content: center;
  
}

.input-group1{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px; 
}

.input-group {
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #ccc; 
  border-radius: 30px;
  background-color: white; 
  padding: 10px;
  width: 400px;
  height: 40px;
  
}

.icon {
  margin-right: 10px;
  height: 20px;
}


.search-shop-location,
.search-location,
.detect-location{
  padding: 10px;
  font-size: 16px;
  border: none; 
  width: 250px; 
}

.submit-location {
  background-color: #000000;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  align-items: center;
  display: flex;
}

.submit-location:hover {
  background-color: #2b2b2b;
}

/* .trythisinappcss{
  position: relative;
} */

.printer-image{
  z-index: -1;
  opacity: 90%;
  position: absolute;
  bottom: 190vh;
  right: 0px;
  height: 400px;
}

.user-Recent-div{
  display: grid;
  align-items: center;
  grid-template-columns: 35px 300px 300px 35px;
  margin-top: 100px;
  margin-left: 100px;
}

.user-usp-erox{
  margin-top: 200px; 
  display: flex;
  justify-content: center;
}

.user-how-erox{
  margin-top: 200px;
  display: flex;
  background-color: rgb(48, 47, 47);
  height: 400px;
  align-items: center;
  flex-direction: column; 
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  padding: 10px;

}

.user-about-erox{
  margin-top: 100px;
  padding-left:200px;
  padding-right: 200px;
}

.footer{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}

.user-settings-form{
  display: flex;
  column-gap: 50px;
  /* height: 400px; */
  flex-direction: row;
  column-gap: 120px;
  justify-content: center;
}

.copies-option{
display: grid;
grid-template-rows: 30px 50px;
}

.copies{
  display: grid;
  grid-template-columns: 100px 50px;
  border-radius: 10px;
  margin-right: 47px;
  height: 52px;
}

.copies-No{

  text-align:center;
  align-items:center;
  margin:0px;
  width:90%;
  height: 45px;
  border-radius: 9px;
  font-size: 2em;
  margin-left: 1px;
  
}

.copies-No::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

.copies-buttons{
  display:grid;
  height: 50px;
  margin: 0px
}

.copies-no-symbol{
  border-radius:10px;
  font-size:10px
}

.papersize-title{
  margin: 0px;
  margin-bottom:8px
}

.papersize-textbox{
  height:50px;
  width:150px;
  display:flex;
  align-items:center;
  border-radius:10px;
  border: 1px solid black

}

.papersize-text{
  margin-left:20px
}
.pages-title{
  margin: 0;
  margin-bottom:10px
}

button:hover{
  opacity: 80%;
}
.selected{
  background-color:cyan
}

.user-settings-form button{
  border-radius:10px
}
/* --------------------------custom-meta-container styles ------------------------------*/
.custom-meta-container button{
  border-radius: 10px;
}


.metadata-options .side-options{
  display: grid;
  grid-template-rows: 30px 50px;
}
.metadata-options .layout-options{
  display: grid;
  grid-template-rows: 30px 50px;
}
.metadata-options .color-options{
  display: grid;
  grid-template-rows: 30px 50px;
}
.metadata-options .color-title{
  margin:2px 0px
}

.metadata-options .layout-title{
  margin:2px 0px
}
.metadata-options .side-title{
  margin:2px 0px
}
.metadata-options .settings-buttons{
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
  align-content: center;
}
.metadata-options .side-buttons{
  display: grid;
  grid-template-columns: 80px 80px;
  column-gap: 1px
}
.metadata-options .layout-buttons{
  display: grid;
  grid-template-columns: 80px 80px;
  column-gap: 1px
}
.metadata-options .color-buttons{
  display: grid;
  grid-template-columns: 80px 80px;
  column-gap: 1px
}
.metadata-options .copies-No{
  text-align:center;
  align-items:center;
  margin:0px;
  width:90%;
  height: 45px;
  border-radius: 9px;
  font-size: 2em;
  margin-left: 1px;
}

.copies-No::-webkit-inner-spin-button{
  -webkit-appearance: none;
}



.metadata-options .copies-buttons{
  display:grid;
  height: 50px;
  margin: 0px
}
.custom-meta-container {
  width: 700px;
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 2px; 
}
.custom-meta-container h3 {
  margin: 10px 0; 
}
.file-list ul {
  list-style-type: none; 
  padding-left: 0; 
  margin: 5px 0; 
}
.order-list{
  width: 450px;
  height: 380px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: auto;
}

.file-list{
  width: 250px;
  height: 380px;
  overflow: auto;
}
.file-list li {
  padding: 4px; 
}
.metadata-options {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

#pdf-viewer canvas{
  
  z-index: 1000;
  height:100%;
  width: 100%;
}

.pdf-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px;
  overflow-y: auto;
  height: 500px;
  width: 800px;
  padding: 20px;
  border-radius: 5px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1001; */
  background-color: white;
}

.pdf-container .close-button {
  position: absolute;
  top: 25px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  margin-left: 50px;
}

.close-button {
  position: sticky;
  z-index: 1002;
  height: 30px;
  width: 30px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-button{
  background-color: cyan;
  height: 40px;
  width: 80px;
  margin-left: 80px;
}
/*------------------------------------------------------------------------------------------*/

.configure-settings-button{
  height: 60px;
  width: 100px; 
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
}

.side-options,.layout-options,.color-options{
  display: grid;
  grid-template-rows: 30px 50px;
}

.side-title,.layout-title,.color-title{
  margin:0px
}

.side-buttons,.layout-buttons,.color-buttons{
  display: grid;
  grid-template-columns: 90px 90px;
  column-gap: 5px
}

.final-buttons{
  display: grid;
  grid-template-columns: 150px 150px ;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}


.upload-button{
  height: 50px;
  width: 120px;
  border-radius: 50px
}

.upload-button:active{
  height: 50px;
  width: 120px;
  border-radius: 50px;
  background-color: aqua;
}


.upload-info{
  display: grid;
  grid-template-rows: 35px 45px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.input-container{
  display: grid;
  grid-template-rows:300px 70px;
  row-gap: 30px;
  height: 400px;
  width: 600px;
  margin-left: 100px;
  margin-bottom: 0px;
  /* border-radius:10px;
  border: 1px solid black */

}

.files-buttons{
  display: grid;
  grid-template-columns: 100px 100px;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
}

.input-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  font-size: 20px;
  border-color: aqua;
}

.input-button:hover{
  width: 600px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  font-size: 20px;
  background-color: rgba(0, 255, 255, 0.433);
}

.upload-icon{
  margin-right:10px;
  height:45px;
  width:45px;
  padding-right:10px
}

.file-input{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}

.settings-button-divs{
  display: flex;
  height: 430px;
  width: 450px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.settings-buttons{
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 100px 100px;
  row-gap: 30px;
  column-gap: 30px;
  align-content: center;
}

.usperox{
  display: grid; 
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 50px;
  justify-content: center;
  
}

.erox-usp-imgs{
  margin-right:50px;
  height:150px
}

.footer-logos{
  display:flex;
  flex-direction:row;
  justify-content:center;
  column-gap:120px
}

.footer-erox-img{
  height: 200px;
}



@media (max-width:900px){

  body{
    margin-left: 0;
    padding-left: 0;
  }

  .mainheader{
    position: fixed;
    top:0px;
    display: grid;
    grid-template-columns: 30vw 70vw;
    background-color: rgb(14, 14, 14);
    height: 70px;
    align-items: center;
    z-index: 100;
    width: 100vw;
  }

  .header-nav-list{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    text-decoration: none;
    list-style-type: none;
  }
  
  .image-logo-div{
    margin-left:15px ;
    padding-bottom: 15px;
  }
  
  .logo-image{
    height: 30px;
    padding-top: 15px;
  }

  .header-opts{
    text-decoration: none;
    font-size: 0.7rem;
  }

  .header-signin-out{
    text-decoration:none;
    color:white; 
    background-color:transparent;
    border:none;
    font-size: 0.7rem;
  }

  .header-signin-out:active{
    background-color: aqua;
    border-radius: 5px;
    padding: 3px;
  }

  .user-form-div{
    margin-top: 120px;
    display: flex;
    width: 100vw;
  }

  .user-settings-form{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    height: auto;
    width: 100vw;
    padding: 0px; 
    margin-top: 0px;
  }
  
  .input-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: auto;
    width: 100vw;
    margin-top: 0px;
    padding-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }
  
  .files-buttons{
    display: grid;
    grid-template-columns: 100px 100px;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
  }
  
  .input-button{
    width:80%;
    max-width: 80%;
    height: 200px;
    max-height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    font-size: 20px;
    border-color: aqua;
    margin: auto auto;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .upload-icon{
    height:45px;
    width:35px;
    margin-left: 20px;
  }


  .file-input{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }

  .upload-info{
    display: grid;
    grid-template-rows: 25px 35px;
    row-gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


  .settings-button-divs{
    padding-left: 0px;
    margin-left: 0px;
    display: flex;
    width: 100vw;
    flex-direction: column;
    row-gap: 50px;
  }

  .settings-buttons{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: 30px;
    width: 100vw;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    
  }
  
  .copies-option{
    display: grid;
    grid-template-rows: 30px 50px;
    width: 150px;
    }
    
    .copies{
      display: grid;
      grid-template-columns: 100px 50px;
      align-items: center;
      margin-right: 47px;
      height: 52px;
    }
    
    .copies-No{
      text-align:center;
      align-items:center;
      margin:0px;
      width:90%
    }
    
    .copies-buttons{
      display:grid;
      height: 50px;
      margin: 0px
    }
    
    .copies-no-symbol{
      border-radius:10px;
      font-size:10px;
    }
    
    .color-options{
      display: grid;
      grid-template-rows:30px 50px;
      width: 185px;
    }
    
    .color-title{
      margin:0px;
    }
    
    .color-buttons{
      display: grid;
      grid-template-columns: 90px 90px;
      column-gap:5px
    }
    
    .papersize-title{
      margin: 0px;
      margin-bottom:8px;
    }
    
    .papersize-textbox{
      height:50px;
      width:150px;
      display:flex;
      align-items:center;
      border-radius:10px;
      border: 1px solid black
    
    }
    
    .papersize-text{
      margin-left:20px
    }
    
    .side-options{
      display: grid;
      grid-template-rows: 30px 50px;
      width: 185px;
    }
    
    .side-title{
      margin:0px
    }
    
    .side-buttons{
      display: grid;
      grid-template-columns: 90px 90px;
      column-gap: 5px
    }
    
    .upload-button{
      height: 50px;
      width: 120px;
      border-radius: 50px
    }
    
    .upload-button:active{
      height: 50px;
      width: 120px;
      border-radius: 50px;
      background-color: aqua;
    }
  
  .printer-image{
    position: absolute;
    z-index: -1;
    opacity: 90%;
    bottom: 1260px;
    right: 0px;
    height: 180px;
  }

  .user-usp-erox{
    margin-top: 200px; 
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  .usperox{
    display: grid; 
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 20px;
    justify-content: center;
    
  }

  .erox-usp-imgs{
    margin-right:10px;
    height:80px
  }

  .user-how-erox{
    margin-top: 100px;
    display: flex;
    background-color: rgb(48, 47, 47);
    height: 250px;
    align-items: center;
    flex-direction: column; 
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 50px;
    padding: 10px;
  
  }

  .user-about-erox{
    margin-top: 100px;
    padding-left:50px;
    padding-right: 50px;

  }

  .footer-logos{
    display:flex;
    flex-direction:row;
    justify-content:center;
    column-gap:40px
  }
  
  .footer-erox-img{
    height: 100px;
    width: 250px
  }
  
  /* --------------------------custom-meta-container styles ------------------------------*/
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-dialog {
    /* height: 400px; */
    /* width: 500px; */
    width: 80%;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
  }
  
  .custom-meta-container {
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 2px; 
  }
  
  .custom-meta-container button{
    border-radius: 10px;
  }
  
  .metadata-options {
    width: 115%;
    height: 250px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 2px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
  }

  .metadata-options .side-options{
    display: grid;
    grid-template-rows: 25px 25px;
  }
  .metadata-options .layout-options{
    display: grid;
    grid-template-rows: 25px 25px;
  }
  .metadata-options .color-options{
    display: grid;
    grid-template-rows: 25px 25px;
  }
  .metadata-options .color-title{
    margin:2px 0px
  }
  .metadata-options .layout-title{
    margin:2px 0px
  }
  .metadata-options .side-title{
    margin:2px 0px
  }
  .metadata-options .settings-buttons{
    width: 350px;
    display: grid;
    row-gap: 1px;
    column-gap: 1px;
    align-content: center;
  }
  .metadata-options .side-buttons{
    display: grid;
    grid-template-columns: 80px 80px;
    column-gap: 1px
  }
  .metadata-options .layout-buttons{
    display: grid;
    grid-template-columns: 80px 80px;
    column-gap: 1px
  }
  .metadata-options .color-buttons{
    display: grid;
    grid-template-columns: 80px 80px;
    column-gap: 1px
  }
  .metadata-options .copies-title{
    text-align:center;
    align-items:center;
    margin:0px;
    width:90%;
    height: 45px;
    border-radius: 9px;
    font-size: 2em;
    margin-left: 1px;
  }

  .metadata-options .copies-buttons{
    display:grid;
    height: 50px;
    margin: 0px
  }
  .custom-meta-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 2px; 
  }
  .custom-meta-container h3 {
    margin: 10px 0; 
  }
  
  .list-preview-div{
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }

  .pdf-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px;
    overflow-y: auto;
    height: 500px;
    width: 80%;
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1001; */
    background-color: white;
  }
  
  .pdf-container .close-button {
    position: absolute;
    top: 25px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    margin-left: 50px;
  }

  .file-list ul {
    list-style-type: none; 
    padding-left: 0; 
    margin: 5px 0; 
  }
  .file-list li {
    padding: 4px; 
  }

  .order-list{
    width: 350px;
    height: 250px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: auto;
  }

  .file-list{
    width: 150px;
    height: 135px;
    overflow: auto;
  }
  
  /*------------------------------------------------------------------------------------------*/
}




